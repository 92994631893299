/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';
const { Money } = sdkTypes;
const depositPercentage = process.env.REACT_APP_DEPOSIT_PERCENTAGE
  ? Number(process.env.REACT_APP_DEPOSIT_PERCENTAGE)
  : 10;
const LineItemDeposit = props => {
  const { transaction, isProvider, intl, lineItems, listing } = props;
  const label = 'Deposit';
  const isSwitchedToProduct = lineItems?.find(l => l?.code === 'line-item/item');
  if (isProvider || isSwitchedToProduct) {
    return null;
  }

  const publicData = listing?.attributes?.publicData || {};
  const depositAmount = publicData?.depositPrice ? Number(publicData?.depositPrice) : 10000;
  const totalPrice = new Money(depositAmount, transaction.attributes.payinTotal?.currency);
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <div className={css.depositWrapper}>
      <div key={`deposit-item.code`} className={css.lineItem}>
        <span className={css.itemLabel}>{label}</span>
        <span className={css.totalPrice}>{formattedTotalPrice}</span>
      </div>
      <p className={css.info}>
        *The security deposit acts as a temporary authorization on the payment card. It will be
        authorized when your booking starts and released within 4 days after the handbag is returned
        undamaged.
      </p>
    </div>
  );
};

LineItemDeposit.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDeposit;
